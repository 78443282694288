@use "../abstract/mixins"as mixin;
@use "../abstract/responsive"as rwd;

$c-white: #FFFFFF;
$c-black: #000000;
$c-heather: #BDCAD6;
$c-silver: #cccccc;
$c-concrete-solid: #F2F2F2;
$c-pickled-bluewood: #283747;

$border-radius: 3px;
$transition: all .4s ease;

/* YT SHORTS SLIDER z POPUP */
.s-shortsYtSliderPopUp {
  @include mixin.marginTopBottom();
  @include mixin.paddingTopBottom();
  background-color: var(--promo-box-slider-bg);
  min-height: 576px;
  @include mixin.sliderLoader("[data-loader-slider]");

  &.contentVisibility {
    content-visibility: visible;
  }

  @include rwd.breakpoint(small) {
    min-height: 519px;
  }

  @include rwd.breakpoint(smallUp) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include rwd.breakpoint(tablet) {
    min-height: 496px;
  }

  @include rwd.breakpoint(tabletmobile) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include rwd.breakpoint(largePhones) {
    min-height: 476px;
  }

  @include rwd.breakpoint(mediumPhones) {
    min-height: 428px;
  }

  @include rwd.breakpoint(smallPhones) {
    min-height: 482px;
  }

  &+.mainFooter {
    margin-top: 40px;
  }

  .sectionTitle,
  .sectionTitle a {
    color: $c-white;
  }

  /* SLIDER */
  .splide {
    @include rwd.breakpoint(smallUp) {
      .splide__arrow {
        top: 36% !important;
        background: transparent;
        height: 44px;
        width: 21px;
        @include mixin.centerer(false, true);

        &:before {
          display: block;
          transition: $transition;
        }

        &:hover,
        &:focus {
          &:before {
            /*   color: var(--main-color-dark); */
          }
        }

        svg {
          display: none;
        }

        &--next {
          right: -40px;

          &:before {
            @include mixin.fontelloIcon("\e845", $c-white, 42px);
          }
        }

        &--prev {
          left: -40px;

          &:before {
            @include mixin.fontelloIcon("\e827", $c-white, 42px);
          }
        }
      }
    }

    &.arrowsMobilePopUpSlider {
      .splide__arrow {
        @include rwd.breakpoint(tabletmobile) {
          bottom: -15px;
          top: auto !important;
          border-radius: $border-radius;
          background-color: $c-concrete-solid;
          height: 30px;
          width: 30px;

          &::before {
            display: none;
          }

          &::after {
            content: "";
            position: absolute;
            transition: $transition;
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 7px solid $c-black;
            transform: rotate(0deg);
          }

          &[disabled] {
            opacity: 1;
            visibility: visible;
          }

          &:focus {
            outline: 2px solid $c-concrete-solid;
            outline-offset: 3px;
          }
        }

        @include rwd.breakpoint(mobile) {
          bottom: -20px;
        }

        svg {
          display: none;
        }

        &--next {
          @include rwd.breakpoint(tabletmobile) {
            right: 7px;

            &::after {
              transform: rotate(0deg);
            }
          }

          @include rwd.breakpoint(smallPhones) {
            right: 0;
          }
        }

        &--prev {
          @include rwd.breakpoint(tabletmobile) {
            left: 7px;

            &::after {
              transform: rotate(180deg);
            }
          }

          @include rwd.breakpoint(smallPhones) {
            left: 0;
          }
        }
      }

      .slideNumber {
        @include rwd.breakpoint(smallUp) {
          display: none;
        }

        @include rwd.breakpoint(tabletmobile) {
          margin-top: 20px;
          margin-bottom: 0;
          text-align: center;
          font-size: 15px;
          color: $c-white;
        }

        &:not(:last-of-type) {
          display: none;
        }
      }
    }

  }

  &__slide {
    display: flex;
    justify-content: center;
  }

  &__item {
    @media screen and (min-width: 1681px) {
      max-width: 200px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1680px) {
      max-width: 172px;
    }

    @include rwd.breakpoint(small) {
      max-width: 130px;
    }

    @include rwd.breakpoint(tablet) {
      /*  768px - 991px */
      max-width: 180px;
    }

    @include rwd.breakpoint(largePhones) {
      /*  576px - 768px */
      max-width: 160px;
    }

    @include rwd.breakpoint(mediumPhones) {
      /* 479px - 576px */
      max-width: 134px;
    }

    @include rwd.breakpoint(smallPhones) {
      /* max 479px */
      max-width: 162px;
    }
  }

  &__iframe {
    border-color: transparent;
  }

  &__img {
    aspect-ratio: 9 / 16;
    object-fit: cover;

    @media screen and (min-width: 1681px) {
      max-width: 200px;
      max-height: 360px;
    }

    @media screen and (min-width: 1200px) and (max-width: 1680px) {
      max-width: 172px;
      max-height: 302px;
    }

    @include rwd.breakpoint(small) {
      /*  992px - 1199px */
      max-width: 130px;
      max-height: 250px;
    }

    @include rwd.breakpoint(tablet) {
      /*  768px - 991px */
      max-width: 180px;
      max-height: 318px;
    }

    @include rwd.breakpoint(largePhones) {
      /*  576px - 768px */
      max-width: 160px;
      max-height: 282px;
    }

    @include rwd.breakpoint(mediumPhones) {
      /* 479px - 576px */
      max-width: 134px;
      max-height: 236px;
    }

    @include rwd.breakpoint(smallPhones) {
      /* max 479px */
      max-width: 100%;
      max-height: 290px;
    }
  }

  &__title {
    margin: 10px 6px;
    @include mixin.showLines(3);
    color: $c-white;

    @include rwd.breakpoint(large) {
      @include mixin.font(15px, 20px, 600);
    }

    @include rwd.breakpoint(desktopsDown) {
      @include mixin.font(14px, 18px, 400);
    }

    @include rwd.breakpoint(smallPhones) {
      /* max 479px */
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__time {
    margin: 0 6px;

    @include rwd.breakpoint(tabletmobile) {
      display: none;
    }

    @include rwd.breakpoint(smallUp) {
      @include mixin.font(14px, 14px, 400);
      color: $c-heather;
    }
  }

  &__coverImg {
    position: relative;
  }

  /* PRZYCISK PLAY */

  /*     svg zakomentowano 07.2024 bo psuje scrypt js kliknięcia na button play obecnie img do button dodano jako background-image w twig TODO do przywrócenia lub img lub svg - bo przekazywanie stylu button samym background-image nie jest bezpeczne */

  /* .s-shortsYtSliderPopUpPlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      z-index: 3;
      transform: translate(-50%, -50%);
      background-color: transparent;
      transition: opacity .25s cubic-bezier(0, 0, .2, 1);
      cursor: pointer;

      @include rwd.breakpoint(large) {
        left: calc(50% - 1px);
        width: 60px;
        height: 64px;
      }

      @include rwd.breakpoint(desktopsDown) {
        left: calc(50% - 1px);
        width: 50px;
        height: 50px;
      }
    }
  } */

  /* POJEDYNCZY FILMIK W POPUP */
  .playShort {
    /*  visibility: hidden; */
    @include mixin.position(fixed, 0, 0, 0, 0);
    z-index: 100;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $transition;
    background-color: $c-pickled-bluewood;
  }

  &__coverSingleIframe {
    &.playShort {
      visibility: visible;

      .s-shortsYtSliderPopUpPlay__coverIframe {
        max-width: 310px;
        max-height: 548px;
        aspect-ratio: 9/16;
      }

      .closeShortBtn {
        display: block;
      }

      .s-shortsYtSliderPopUp__buttons {
        display: flex;
      }

      /*       .s-shortsYtSliderPopUp__iframeHeader {
        @include rwd.breakpoint(phonesUp) {
          display: flex;
        }
      } */
    }
  }

  /* header w popUp zakomentowano 26,07,2024 */
  /*   &__iframeHeader {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--promo-box-slider-bg);
    padding: 0 20px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;

    @include rwd.breakpoint(smallPhonesUp) {
      height: 46px;
    }

    @include rwd.breakpoint(smallPhones) {
      height: 40px;
    }

    >img {
      @include rwd.breakpoint(smallPhones) {
        height: 24px;
      }
    }
  } */

  &__iframeImg {}

  .closeShortBtn {
    @include mixin.position(absolute, 10px, 10px);
    display: none;
    border: solid 1px transparent;
    border-radius: 3px;
    padding: 6px;
    background: transparent;
    font-size: 18px;
    color: $c-white;
    transition: $transition;
    cursor: default;

    @include rwd.breakpoint(phonesDown) {
      top: 4px;
      right: 4px;
      padding: 6px;
      font-size: 14px;
    }

    &:hover,
    &:focus,
    &:active {
      @include rwd.breakpoint(smallUp) {
        opacity: 0.5;
      }
    }
  }

  &__coverIframe {
    >iframe {
      @media screen and (min-width: 992px) and (max-width: 1599px) {
        margin-top: 40px;
      }

      @include rwd.breakpoint(smallPhones) {
        margin-top: 10px;
      }
    }
  }

  &__buttons {
    position: absolute;
    display: none;
    height: 490px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: 1600px) {
      right: calc(50% - 30px);
      height: 672px;
    }

    @media screen and (min-width: 992px) and (max-width: 1599px) {
      right: calc(50% - 20px);
      height: 520px;
    }

    @media screen and (min-width: 480px) and (max-width: 991px) {
      right: calc(50% - 20px);
      height: 564px;
    }

    @include rwd.breakpoint(smallPhones) {
      right: calc(50% - 18px);
      height: 564px;
    }

    @media screen and (max-width: 376px) {
      height: 490px;
    }

    .s-shortsYtSliderPopUpButton {
      position: absolute;
      right: 0;
      border-radius: 3px;
      border: solid 1px $c-white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      transition: $transition;

      @include rwd.breakpoint(smallUp) {
        width: 60px;
        height: 60px;
      }

      @media screen and (min-width: 480px) and (max-width: 1599px) {
        width: 40px;
        height: 40px;
      }

      @include rwd.breakpoint(smallPhones) {
        width: 36px;
        height: 26px;
      }

      &--top {
        top: -76px;

        @media screen and (min-width: 992px) and (max-width: 1599px) {
          top: -34px;
        }

        @media screen and (min-width: 480px) and (max-width: 991px) {
          top: -52px;
        }

        @include rwd.breakpoint(smallPhones) {
          top: -30px;
        }

        @media screen and (max-width: 376px) {
          top: -32px;
        }
      }

      &--bottom {
        @include rwd.breakpoint(smallUp) {
          bottom: -70px;
        }

        @media screen and (min-width: 480px) and (max-width: 991px) {
          bottom: -48px;
        }

        @include rwd.breakpoint(smallPhones) {
          bottom: -40px;
        }

        @media screen and (max-width: 376px) {
          bottom: -34px;
        }
      }


      .icon-zielonyup-open,
      .icon-zielonydown-open {
        color: $c-white;

        @include rwd.breakpoint(mobileUp) {
          font-size: 10px;
        }

        @media screen and (min-width: 480px) and (max-width: 767px) {
          font-size: 8px;
        }

        @include rwd.breakpoint(smallPhones) {
          font-size: 8px;
        }
      }

      &.hidden {
        display: none;
      }

      &:hover,
      &:focus,
      &:active {
        @include rwd.breakpoint(smallUp) {
          opacity: 0.5;
        }
      }
    }
  }
}

/* OWERWRIGHTS FOR PRESIDENT */
body[data-main="prezydent"] {
  .s-shortsYtSliderPopUp {
    .splide {
      @include rwd.breakpoint(smallUp) {
        .splide__arrow {

          &:hover,
          &:focus {
            &:before {
              color: var(--main-color-dark);
            }
          }
        }
      }
    }
  }
}

/* OWERWRIGHTS DLA POWÓDZ */
#floodShortsYtSlider.s-shortsYtSliderPopUp {
  @include rwd.breakpoint(tabletmobile) {
    margin-top: 0;
    margin-bottom: 20px !important;
  }
}

#floodShortsYtSlider {
  $c-bombay: #B6B9BD;

  .s-shortsYtSliderPopUp {
    &__title {
      @include rwd.breakpoint(tabletmobile) {
        margin-bottom: 6px;
        @include mixin.font(12px, 16px, 400);
      }
    }

    &__time {
      @include rwd.breakpoint(tabletmobile) {
        display: inline-block;
        font-size: 12px;
        color: $c-bombay;
      }

      @include rwd.breakpoint(smallPhones) {
        /* max 479px */
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}